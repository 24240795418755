import React from 'react';
import { login, logout, isAuthenticated, getProfile } from "../utils/auth"

import { Button } from '@material-ui/core'

const LoginButton = () => {
    return !isAuthenticated() && (
        <Button variant="contained" onClick={login}>Log in</Button>
    );
}


const LogoutButton = () => {
    return isAuthenticated() && (
        <Button variant="contained" onClick={e => {
            logout('https://test.flockalong.com')
            e.preventDefault()
        }}>Log out</Button>
    );
}

export const Logging = () => {
    return (
        <div>
            { isAuthenticated() ? <LogoutButton /> : <LoginButton />}
        </div>
    )
} 