import {
  AppBar,
  Container,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Toolbar,
  Fab,
  Typography
} from "@material-ui/core";
import { Home, KeyboardArrowUp } from "@material-ui/icons";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import { Link } from 'gatsby'

import * as React from "react";
import HideOnScroll from "./navigation/HideOnScroll";
import SideDrawer from "./navigation/SideDrawer";
import BackToTop from "./navigation/BackToTop";
import { Logging } from '../components/logging'
import { isAuthenticated } from "../utils/auth"


import PropTypes from "prop-types"

const useStyles = makeStyles((theme) => ({
  navbarDisplayFlex: {
    display: `flex`,
    justifyContent: `space-between`,
    maxWidth: '1240px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px'
    },
    padding: '0 40px'
  },
  navListDisplayFlex: {
    display: `flex`,
    justifyContent: `space-between`,
    whiteSpace: 'nowrap'
  },
  linkText: {
    textDecoration: `none`,
    textTransform: `uppercase`,
    color: `white`
  },
  boldText: {
    fontWeight: "bold"
  }
}));

const navLinks = [
  { title: `home`, path: `/home` },
  { title: `trip`, path: `/trip/create-trip` },
  { title: `about us`, path: `/about-us` },
];

const additionalNavLinks = [
  { title: `profile`, path: "/user/profile" },
]

const Header = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <HideOnScroll>
        <AppBar position="fixed">
          <Toolbar component="nav">
            <Container className={classes.navbarDisplayFlex}>
              <IconButton edge="start" aria-label="home">
                <Link to="/" style={{ color: `white` }}>
                  FLOCKALONG
                </Link>
              </IconButton>

              <Hidden smDown>
                <List
                  component="nav"
                  aria-labelledby="main navigation"
                  className={classes.navListDisplayFlex}
                >
                  {navLinks.map(({ title, path }) => (
                    <Link to={path} key={title} className={classes.linkText}>
                      <ListItem button>
                        <ListItemText primary={<Typography className={classes.boldText} variant="body2" component="span">{title}</Typography>} />
                      </ListItem>
                    </Link>
                  ))}
                  {
                    isAuthenticated() ? (
                      <ListItem button>
                        <AccountCircleIcon
                          ref={anchorRef}
                          aria-controls={open ? 'menu-list-grow' : undefined}
                          aria-haspopup="true"
                          onClick={handleToggle} />
                        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                              <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    <MenuItem onClick={handleClose}><Link to="/user/profile">Profile</Link></MenuItem>
                                    <MenuItem onClick={handleClose}><Logging /></MenuItem>
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      </ListItem>
                    )
                      :
                      <ListItem button>
                        <Logging />
                      </ListItem>

                  }

                </List>
              </Hidden>
              <Hidden mdUp>
                <SideDrawer navLinks={navLinks} additionalNavLinks={additionalNavLinks} />

              </Hidden>
            </Container>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar id="back-to-top-anchor" />

      <BackToTop>
        <Fab color="secondary" size="large" aria-label="scroll back to top">
          <KeyboardArrowUp />
        </Fab>
      </BackToTop>
    </>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
