import auth0 from "auth0-js"
import { navigate } from "gatsby"

const isBrowser = typeof window !== "undefined"

const auth = isBrowser
    ? new auth0.WebAuth({
        domain: process.env.GATSBY_AUTH0_DOMAIN,
        clientID: process.env.GATSBY_AUTH0_CLIENTID,
        redirectUri: "http://localhost:8000/authcallback",
        responseType: "token id_token",
        scope: "openid profile email",
    })
    : {}

const tokens = {
    accessToken: false,
    idToken: false,
    expiresAt: false,
}

let user = {}

//to validate if login is needed
export const isAuthenticated = () => {
    if (!isBrowser) {
        return
    }
    return localStorage.getItem("isLoggedIn") === "true"
}


export const login = () => {
    if (!isBrowser) {
        return
    }
    auth.authorize()
}

export const logout = (logoutRedirectUri) => {
    console.log(logoutRedirectUri)
    localStorage.setItem("isLoggedIn", false)
    auth.logout({ returnTo: logoutRedirectUri })
}

//create session
const setSession = (cb = () => { }) => (err, authResult) => {
    if (err) {
        navigate("/")
        cb()
        return
    }

    if (authResult && authResult.accessToken && authResult.idToken) {
        let expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
        tokens.accessToken = authResult.accessToken
        tokens.idToken = authResult.idToken
        tokens.expiresAt = expiresAt
        user = authResult.idTokenPayload
        localStorage.setItem("isLoggedIn", true)
        navigate(window.location)
        cb()
    }
}

//authenticate a session and login user //used in gatsby-browser.js
export const silentAuth = callback => {
    if (!isAuthenticated()) return callback()
    auth.checkSession({}, setSession(callback))
}

export const handleAuthentication = () => {
    if (!isBrowser) {
        return
    }
    auth.parseHash(setSession())
}

//fetch user details
export const getProfile = () => {
    return user
}